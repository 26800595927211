import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "gatsby";

const BlogPost = ({ className, thumbUrl, title, excerpt, link, url }) => {
  // Add all classes to an array
  const addAllClasses = ['blog_post'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div className={addAllClasses.join(' ')}>
      {thumbUrl && (
        <div className="thumbnail">
          <img src={thumbUrl} alt={title} />
        </div>
      )}
      <div className="content">
        { title && <h3 className="title" style={{ color: '#355E3B', fontWeight: 500 }} >{title}</h3> }
        {excerpt && <p className="excerpt">{excerpt}</p>}
        {link && <div className="learn_more">{link}</div>}
      </div>
    </div>
  );
};

BlogPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
};

export default BlogPost;
