import React from 'react';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import BlogPost from 'common/components/BlogPost';
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  Text,
  PostArea,
} from './blogSection.style';
import {externalLink} from 'react-icons-kit/feather/externalLink'
import { Icon } from 'react-icons-kit'

const BlogSection = ({ data }) => {
  return (
    <SectionWrapper id="blog">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content={ data.title } />
            <Text>
              { data.subtext }
            </Text>
          </TitleArea>
        </SectionHeader>
        <PostArea>
          { data.items.map((item, index) => (
              <BlogPost
                key={`blog-post-${index}`}
                title={ item.title }
                excerpt={ item.description }
                link={
                  <a className="learn__more-btn"
                     target="_blank"
                     rel="noreferrer"
                     href={item.document.url}>
                    <Icon icon={externalLink}  style={{ paddingRight: '1em', width: '20px', height: '23px' }} />
                    <span className="btn_text">View Document</span>
                  </a>
                }
              />
          )) }
        </PostArea>
      </Container>
    </SectionWrapper>
  );
};

export default BlogSection;
