import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Heading from 'common/components/Heading';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from './bannerSection.style';

const BannerSection = ({data}) => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <Heading
            as="h2"
            content={ data.title }
          />
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
          { data.hasButton === true && (
            <Link className="learn__more-btn" to={ data.buttonUl }>
              <span className="hyphen" />
              <span className="btn_text">{ data.buttonText }</span>
            </Link>
          )}
        </TextArea>
        <ImageArea style={{ background: `url('${data.image.url}')` , backgroundSize: 'cover', backgroundPositionX: 'right', backgroundPositionY: 'center'}} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
