import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import BlogPost from 'common/components/BlogPost';
import Container from 'common/components/UI/Container';
import SectionWrapper, {
  SectionHeader,
  FeatureWrapper,
} from './featureSection.style';

const FeatureSection = ({ data }) => {
  let style = {};
  let headerStyle = {}

  if (data.paddingTop) {
    style.paddingTop = data.paddingTop;
  }

  if (data.paddingBottom) {
    style.paddingBottom = data.paddingBottom;
  }

  if (data.headersLeft === true) {
    headerStyle.textAlign = 'left';
  }

  return (
    <SectionWrapper id="feature" style={style}>
      <Container width="1260px">
        <SectionHeader>
          <Heading content={ data.title } style={headerStyle} />
          <Text content={ data?.subtitle } style={headerStyle} />
          { data?.bottomText && <div className="bottom-text-item" dangerouslySetInnerHTML={{ __html: data?.bottomText }} /> }
        </SectionHeader>
        <FeatureWrapper>
          { data.items.map((item, index) => (
            <BlogPost
              key={`picto-list-${index}`}
              thumbUrl={ item?.icon?.url }
              title={item.title}
              excerpt={item?.text}
              url={`/read-more`}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default FeatureSection;
