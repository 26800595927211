import React, { Fragment } from 'react';

import HeaderBlock from "../HeaderBlock";
import BecomeCouncillorForm from "../BecomeCouncillorForm";

import {
  ContentWrapper,
} from 'containers/Charity/charity.style';
import PictoListSection from "../PictoListSection";
import DocumentsSection from "../DocumentsSection";
import TextBlock from "../TextBlock";
import ESU from "../ESU";
import SupportUsSection from "../SupportUsSection";

const ComponentRenderer = ({ data }) => {
  return (
    <ContentWrapper>
      {data.components.map((component, key) => {
        if (component.model.name === 'Header Block') {
          return (
            <HeaderBlock key={`component-${key}`} data={component} />
          );
        }

        if (component.model.name === 'Become a Councillor Form') {
          return (
            <BecomeCouncillorForm key={`component-${key}`} data={component} />
          );
        }

        if (component.model.name === 'Picto List') {
          return (
            <PictoListSection key={`component-${key}`} data={component} />
          );
        }

        if (component.model.name === 'Documents Block') {
          return (
            <DocumentsSection key={`component-${key}`} data={component} />
          );
        }

        if (component.model.name === 'Text Block') {
          return (
            <TextBlock key={`component-${key}`} text={component.text} />
          )
        }

        if (component.model.name === 'ESU') {
          return (
            <ESU key={`component-${key}`} data={component} />
          )
        }

        if (component.model.name === 'Support Us Section') {
          return (
            <SupportUsSection key={`component-${key}`} data={component} />
          )
        }

        return (
          <Fragment key={`component-${key}`} />
        )
      })}
    </ContentWrapper>
  );
};

export default ComponentRenderer;
