import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Container from 'common/components/UI/Container';
import SectionWrapper, {
  ContentArea,
  Heading,
} from './donateSection.style';
import {Link} from "gatsby";

const DonateSection = ({ data, row, col }) => {
  return (
    <SectionWrapper id="support-us-section">
      <img
        className="demo-bg"
        src={ data.backgroundImage.url }
        alt=""
      />
      <Container width="1260px">
        <Box className="row" {...row}  style={{ position: 'relative', zIndex: 9999 }}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                How you can support us
              </Heading>
              <div>
                <p>If you would like to support us or feel that you have time to get stuck in, there are many ways you can:</p>
                <ul>
                  <li>
                    Volunteer your time
                    <span>During the run up to the local elections, we desperately need people to help us spread the word. Whether that is delivering leaflets, having boards in their gardens or simply telling your friends.</span>
                  </li>
                  <li>
                    Keep up to date
                    <span><a target="_blank" href="https://www.facebook.com/godalmingresidents">Follow us on facebook</a> or <a href="#esu-signup">subscribe to our mailing list</a> to keep up to date with our campaign.</span>
                  </li>
                  <li>
                    Stand as a councillor
                    <span>We need people who are passionate about making a difference in their community, who care more about the area and can see a better way for our community than political tribalism. We need to stand councillors in every ward that we can in 2023 at the local elections, there is still room for candidates! <a href="#become-councillor-form">Register your interest here</a>.</span>
                  </li>
                </ul>
              </div>
            </ContentArea>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    pl: '15px',
    pr: '15px',
    mb: '30px',
    color: '#fff',
  },
};

export default DonateSection;
