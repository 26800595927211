import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.section`
  width: 100%;
  padding: 81px 0 50px;
  margin-top: 70px;
  
  h1 {
    font-size: 2.6em;
    border-bottom: 1px solid #345e3b;
    padding-bottom: 0.3em;
    margin-bottom: 1em;
  }
  
  h2 {
    font-size: 1.8em;
  }
`;

export default SectionWrapper;
