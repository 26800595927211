import React from 'react';
import Container from 'common/components/UI/Container';

import SectionWrapper from './index.style.js';

const TextBlock = ({ text }) => {
  return (
    <SectionWrapper id="blog">
      <Container width="1260px">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Container>
    </SectionWrapper>
  );
};

export default TextBlock;
