import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Container from 'common/components/UI/Container';
import Input from 'common/components/Input';
import Text from 'common/components/Text';
import SectionWrapper, {
  ContentArea,
  Heading,
  DonationForm,
  DonateButton,
  NameWrapper,
} from './donateSection.style';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';

const schema = yup.object().shape({
  firstname: yup.string().required('Your firstname is required'),
  surname: yup.string().required('Your surname is required'),
  number: yup.string().required('Your contact number is required'),
  email: yup.string().email().required('Your email address is required'),
  post_code: yup.string().required('Your post code is required'),
});

const DonateSection = ({ data, row, col }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    setIsSubmitting(true);

    const portalId = '26902566';
    const formGuid = '52ec2c45-2ad2-404e-8662-883cfaefe61d';

    await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'firstname',
            value: data.firstname,
          },
          {
            name: 'lastname',
            value: data.surname,
          },
          {
            name: 'email',
            value: data.email,
          },
          {
            name: 'phone',
            value: data.number,
          },
          {
            name: 'post_code',
            value: data.post_code,
          },
        ],
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      },
    );

    setIsSubmitted(true);
  };

  return (
    <SectionWrapper id="become-councillor-form">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading dangerouslySetInnerHTML={{ __html: data.title.replace('<p>', '').replace('</p>', '') }} />
              <div style={{ fontSize: '1.4em', color: '#fff' }} dangerouslySetInnerHTML={{ __html: data.subtext }} />
            </ContentArea>
          </Box>

          {isSubmitted === true && (
            <Box className="col" {...col}>
              <DonationForm>
                <h2>Submission Received</h2>
                <Text as="p" className="confirmation-text" content={`Thank you for wanting to <strong>stand with residents</strong> and <strong>deliver real change</strong> for our area!`} />
                <Text as="p" className="confirmation-text" content={`Your registration of interest in standing as a councillor for Godalming & Villages Residents has been <strong>submitted to the nominations team</strong>.`} />
                <Text as="p" className="confirmation-text" content={`We will usually get in touch with you within 1-2 days. The next steps are usually for us to arrange a phone call and talk through your reasons for standing, this will usually be followed by an in person meeting.`} />
              </DonationForm>
            </Box>
          )}

          { isSubmitted === false && (
            <Box className="col" {...col}>
              <DonationForm onSubmit={handleSubmit(onSubmitHandler)}>
                <NameWrapper>
                  <div className="item-left">
                    <Input
                      className="input-field"
                      inputType="text"
                      label="Firstname"
                      name="firstname"
                      style={{ padding: '20px' }}
                      placeholder="e.g. John"
                      register={register("firstname")}
                      error={errors.name?.message}
                    />
                  </div>
                  <div className="item-right">
                    <Input
                      className="input-field"
                      inputType="text"
                      label="Surname"
                      name="name"
                      style={{ padding: '20px' }}
                      placeholder="e.g. Smith"
                      register={register("surname")}
                      error={errors.name?.message}
                    />
                  </div>
                </NameWrapper>

                <Input
                  className="input-field"
                  inputType="text"
                  label="Email Address"
                  name="email"
                  style={{ padding: '20px' }}
                  placeholder="e.g. john.smith@microsoft.com"
                  register={register('email')}
                  error={errors.email?.message}
                />
                <Input
                  className="input-field"
                  inputType="text"
                  label="Contact Number"
                  name="number"
                  style={{ padding: '20px' }}
                  placeholder="e.g. 07917 378 123"
                  register={register('number')}
                  error={errors.number?.message}
                />
                <Input
                  className="input-field"
                  inputType="text"
                  label="Post Code"
                  name="post_code"
                  style={{ padding: '20px' }}
                  placeholder="e.g. GU7 1AA"
                  register={register('post_code')}
                  error={errors.post_code?.message}
                />
                <DonateButton type="submit" disabled={isSubmitting}>
                  { isSubmitting ? 'Submitting...' : 'Register Interest' }
                </DonateButton>
              </DonationForm>
            </Box>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default DonateSection;
